import Vue from 'vue';
import axios from 'axios';

const getDefaultState = () => {
	return {
		customPetTickets: 0,
		customPets: [],
		customPending: [],
		customPetForm: {},
		loadingCustomPet: false,
	};
};

const state = getDefaultState();

const getters = {
	customPetTickets: (state) => state.customPetTickets,
	customPets: (state) => state.customPets,
	customPending: (state) => state.customPending,
	customPetForm: (state) => state.customPetForm,
	loadingCustomPet: (state) => state.loadingCustomPet,
};

const actions = {
	getCustomPets: async ({ commit }) => {
		commit('setLoadingCustomPet', true);
		const res = await axios.get(
			`${process.env.VUE_APP_BACKEND}/api/custom-pet`,
			{ withCredentials: true }
		);
		commit('setCustomTicketCount', res.data.available);
		commit('setCustomAnimals', res.data.animals);
		commit('setCustomPending', res.data.pending);
		commit('setLoadingCustomPet', false);
		return res.data;
	},
	updateCustomPetForm: async ({ commit }, { field, value }) => {
		await commit('updateCustomPetForm', { field, value });
	},
	submitCustomPetForm: async (_vue, form) => {
		const formdata = new FormData();
		formdata.append('name', new Blob([form.name], { type: 'text/plain' }));
		formdata.append('hp', new Blob([form.hp], { type: 'text/plain' }));
		formdata.append('att', new Blob([form.att], { type: 'text/plain' }));
		formdata.append('pr', new Blob([form.pr], { type: 'text/plain' }));
		formdata.append('wp', new Blob([form.wp], { type: 'text/plain' }));
		formdata.append('mag', new Blob([form.mag], { type: 'text/plain' }));
		formdata.append('mr', new Blob([form.mr], { type: 'text/plain' }));
		if (form.alt?.length) {
			formdata.append(
				'alt',
				new Blob([JSON.stringify(form.alt)], { type: 'text/plain' })
			);
		}
		if (form.description) {
			formdata.append(
				'description',
				new Blob([form.description], { type: 'text/plain' })
			);
		}

		if (form.event) {
			if (form.artists?.length) {
				formdata.append(
					'artists',
					new Blob([JSON.stringify(form.artists)], { type: 'text/plain' })
				);
			}
			formdata.append('event', new Blob([true], { type: 'text/plain' }));
			formdata.append(
				'eventStart',
				new Blob([new Date(form.eventStart[0]).getTime()], {
					type: 'text/plain',
				})
			);
			formdata.append(
				'eventEnd',
				new Blob([new Date(form.eventEnd[0]).getTime()], { type: 'text/plain' })
			);
		} else {
			if (form.creators?.length) {
				formdata.append(
					'creators',
					new Blob([JSON.stringify(form.creators)], { type: 'text/plain' })
				);
			}
			if (form.gifts?.length) {
				formdata.append(
					'gifts',
					new Blob([JSON.stringify(form.gifts)], { type: 'text/plain' })
				);
			}
		}
		formdata.append('image', form.image);
		return axios.post(
			process.env.VUE_APP_BACKEND + '/api/custom-pet/submit',
			formdata,
			{
				headers: { 'Content-Type': 'multipart/form-data' },
				withCredentials: true,
			}
		);
	},
	resetCustomPetForm: async ({ commit }) => {
		commit('resetCustomPetForm');
	},
};

const mutations = {
	setCustomTicketCount: (state, count = 0) => {
		Vue.set(state, 'customPetTickets', count);
	},
	setCustomAnimals: (state, pets = []) => {
		Vue.set(state, 'customPets', pets);
	},
	setCustomPending: (state, pending = []) => {
		Vue.set(state, 'customPending', pending);
	},
	updateCustomPetForm: (state, { field, value }) => {
		Vue.set(state.customPetForm, field, value);
	},
	resetCustomPetForm: (state) => {
		Vue.set(state, 'customPetForm', {});
	},
	setLoadingCustomPet: (state, loading) => {
		Vue.set(state, 'loadingCustomPet', loading);
	},
};

export default { actions, state, mutations, getters };
